import * as React from "react";

import { clsxm } from "@/lib/clsxm";

import { InputErrorMessage } from "@/components/inputs/InputErrorMessage";

export type TextInputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  error?: string;
  label?: string;
  inputClassName?: string;
};

export const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>(
  (
    { label, name, error, className, inputClassName, type = "text", ...props },
    ref
  ) => {
    return (
      <div className={clsxm("flex w-full flex-col text-left", className)}>
        {label && <label htmlFor={name}>{label}</label>}
        <input
          type={type}
          name={name}
          className={clsxm(
            "rounded border border-gray-300 p-2 text-sm text-gray-900",
            inputClassName
          )}
          {...props}
          ref={ref}
        />
        <InputErrorMessage error={error} />
      </div>
    );
  }
);

import { createContext } from "react";

import {
  NotificationService,
  useNotificationService,
} from "@/lib/notifications";

import { useTenantUuid } from "@/components/hooks/useTenantUuid";

import { BoostUserSchema, useGetCurrentTenantUser } from "@/upsmith-api";

type UserContext = {
  refetch: () => void;
  user: BoostUserSchema;
  notificationService: NotificationService;
};

export const CurrentUserContext = createContext<UserContext>({} as UserContext);

export const CurrentUserProvider = ({
  children,
  displayWhileLoading,
}: {
  children: React.ReactNode;
  displayWhileLoading: React.ReactNode;
}) => {
  const tenantUuid = useTenantUuid();
  const {
    data: user,
    isInitialLoading,
    refetch,
  } = useGetCurrentTenantUser(tenantUuid);

  const notificationService = useNotificationService();

  if (isInitialLoading || !user) {
    return displayWhileLoading;
  }

  return (
    <CurrentUserContext.Provider
      value={{
        refetch,
        user: user,
        notificationService,
      }}
    >
      {children}
    </CurrentUserContext.Provider>
  );
};

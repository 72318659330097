import { useCallback } from "react";

import { TenantOrganizationTranslationMap } from "@/upsmith-api";

import { OrgTranslator } from "./types";
import { toTitleCase } from "./utils";
import { OrgConfig } from "../types";

export const useTranslate = (
  orgConfigContext: OrgConfig
): OrgTranslator["translate"] => {
  return useCallback(
    (key, opts = {}) => {
      const casing = opts.casing || "lower";

      const defaultByKey: Record<
        keyof TenantOrganizationTranslationMap,
        string
      > = {
        point: "point",
        points: "points",
      };

      const translated =
        orgConfigContext.translationMap?.[key] || defaultByKey[key];

      if (casing === "title") {
        return toTitleCase(translated);
      }

      if (casing === "lower") {
        return translated.toLowerCase();
      }

      const exhaustiveCheck: never = casing;
      return exhaustiveCheck;
    },
    [orgConfigContext.translationMap]
  );
};

import { useCallback } from "react";

import {
  BoostModuleAbout,
  BoostModuleAchievements,
  BoostModuleCompetitions,
  BoostModuleLeaderboard,
  BoostModuleRaffles,
  BoostModuleReports,
  BoostModuleRewards,
  BoostModuleSpotAwards,
} from "@/upsmith-api";

import { OrgModuleService } from "./types";
import { OrgConfig } from "../types";

export const useGetModuleConfig = (
  orgConfig: OrgConfig
): OrgModuleService["getModuleConfig"] => {
  return useCallback(
    (moduleName) => {
      if (moduleName === "about") {
        let mod: BoostModuleAbout | null = null;
        orgConfig.modules.forEach((module) => {
          if (module.name === "about") {
            mod = module;
          }
        });

        return mod;
      }

      if (moduleName === "achievements") {
        let mod: BoostModuleAchievements | null = null;
        orgConfig.modules.forEach((module) => {
          if (module.name === "achievements") {
            mod = module;
          }
        });

        return mod;
      }

      if (moduleName === "competitions") {
        let mod: BoostModuleCompetitions | null = null;
        orgConfig.modules.forEach((module) => {
          if (module.name === "competitions") {
            mod = module;
          }
        });

        return mod;
      }

      if (moduleName === "leaderboard") {
        let mod: BoostModuleLeaderboard | null = null;
        orgConfig.modules.forEach((module) => {
          if (module.name === "leaderboard") {
            mod = module;
          }
        });

        return mod;
      }

      if (moduleName === "raffles") {
        let mod: BoostModuleRaffles | null = null;
        orgConfig.modules.forEach((module) => {
          if (module.name === "raffles") {
            mod = module;
          }
        });

        return mod;
      }

      if (moduleName === "reports") {
        let mod: BoostModuleReports | null = null;
        orgConfig.modules.forEach((module) => {
          if (module.name === "reports") {
            mod = module;
          }
        });

        return mod;
      }

      if (moduleName === "rewards") {
        let mod: BoostModuleRewards | null = null;
        orgConfig.modules.forEach((module) => {
          if (module.name === "rewards") {
            mod = module;
          }
        });

        return mod;
      }

      if (moduleName === "spot_awards") {
        let mod: BoostModuleSpotAwards | null = null;
        orgConfig.modules.forEach((module) => {
          if (module.name === "spot_awards") {
            mod = module;
          }
        });

        return mod;
      }

      const exhaustiveCheck: never = moduleName;
      return exhaustiveCheck;
    },
    [orgConfig]
  );
};

import { createClient } from "@propelauth/javascript";
import Axios, { AxiosError, AxiosHeaders, AxiosRequestConfig } from "axios";

import { apiBaseURL } from "@/constant/env";
import { HTTPValidationError } from "@/upsmith-api/schema";

const propelAuthClient = process.env.NEXT_PUBLIC_PROPEL_AUTH_URL
  ? createClient({
      authUrl: process.env.NEXT_PUBLIC_PROPEL_AUTH_URL,
    })
  : null;

const getPropelAccessToken = async function () {
  if (!propelAuthClient) {
    return null;
  }
  const authInfo = await propelAuthClient.getAuthenticationInfoOrNull();
  return authInfo?.accessToken;
};

const AXIOS_INSTANCE = (async function () {
  const headers: Record<string, string> = {};
  const axiosClient = Axios.create({
    baseURL: typeof window === "undefined" ? apiBaseURL : "/upsmith",
    headers: headers,
  });
  axiosClient.interceptors.request.use(
    async (config) => {
      const headers = config.headers as AxiosHeaders;
      if (headers) {
        headers.set("Authorization", `Bearer ${await getPropelAccessToken()}`);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  return axiosClient;
})();

export const axiosInstance = <T>(
  config: AxiosRequestConfig,

  options?: AxiosRequestConfig
): Promise<T> => {
  const source = Axios.CancelToken.source();
  const promise = AXIOS_INSTANCE.then((axios_instance) => {
    return axios_instance({
      ...config,
      ...options,
      cancelToken: source.token,
      paramsSerializer: {
        indexes: null,
      },
    });
  }).then(({ data }) => {
    // console.log(data); // eslint-disable-line
    return data;
  });
  // promise.cancel = () => {
  //   source.cancel("Query was cancelled");
  // };

  return promise;
};

export type ErrorType<Err> = AxiosError<Err>;

export const getAxiosDefaultErrorMessage = (
  e: ErrorType<HTTPValidationError>
) => {
  if (e.response) {
    const responseDetail = e.response.data?.detail;
    if (Array.isArray(responseDetail)) {
      if (responseDetail[0] && responseDetail[0].msg) {
        return `${e.message}: ${responseDetail[0].msg}`;
      }
    } else if (responseDetail) {
      return `${e.message}: ${responseDetail}`;
    }
  }
  return e.message;
};

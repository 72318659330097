/**
 * Generated by orval v6.25.0 🍺
 * Do not edit manually.
 * UpSmith Staging API
 * OpenAPI spec version: 1.0.0
 */

export type BoostModuleAchievementsName =
  (typeof BoostModuleAchievementsName)[keyof typeof BoostModuleAchievementsName];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BoostModuleAchievementsName = {
  achievements: "achievements",
} as const;

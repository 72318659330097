/**
 * Generated by orval v6.25.0 🍺
 * Do not edit manually.
 * UpSmith Staging API
 * OpenAPI spec version: 1.0.0
 */

export type SkuSoldBehaviorCreateSchemaType =
  (typeof SkuSoldBehaviorCreateSchemaType)[keyof typeof SkuSoldBehaviorCreateSchemaType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SkuSoldBehaviorCreateSchemaType = {
  sku_sold: "sku_sold",
} as const;

/**
 * Generated by orval v6.25.0 🍺
 * Do not edit manually.
 * UpSmith Staging API
 * OpenAPI spec version: 1.0.0
 */
import { useQuery } from "@tanstack/react-query";
import type {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import type {
  BoostSearchMembershipTypeResultSchema,
  BoostSearchSkuResultSchema,
  HTTPValidationError,
  SearchMembershipTypesParams,
  SearchSkusParams,
} from ".././schema";
import { axiosInstance } from "../../lib/axios";
import type { ErrorType } from "../../lib/axios";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Search Skus
 */
export const searchSkus = (
  tenantUuid: string,
  params?: SearchSkusParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<BoostSearchSkuResultSchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/erp/skus/search`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getSearchSkusQueryKey = (
  tenantUuid: string,
  params?: SearchSkusParams
) => {
  return [
    `/api/v1/tenants/${tenantUuid}/boost/erp/skus/search`,
    ...(params ? [params] : []),
  ] as const;
};

export const getSearchSkusQueryOptions = <
  TData = Awaited<ReturnType<typeof searchSkus>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  params?: SearchSkusParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof searchSkus>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getSearchSkusQueryKey(tenantUuid, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof searchSkus>>> = ({
    signal,
  }) => searchSkus(tenantUuid, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!tenantUuid,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof searchSkus>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SearchSkusQueryResult = NonNullable<
  Awaited<ReturnType<typeof searchSkus>>
>;
export type SearchSkusQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Search Skus
 */
export const useSearchSkus = <
  TData = Awaited<ReturnType<typeof searchSkus>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  params?: SearchSkusParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof searchSkus>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSearchSkusQueryOptions(tenantUuid, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Search Membership Types
 */
export const searchMembershipTypes = (
  tenantUuid: string,
  params?: SearchMembershipTypesParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<BoostSearchMembershipTypeResultSchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/erp/membership-types/search`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getSearchMembershipTypesQueryKey = (
  tenantUuid: string,
  params?: SearchMembershipTypesParams
) => {
  return [
    `/api/v1/tenants/${tenantUuid}/boost/erp/membership-types/search`,
    ...(params ? [params] : []),
  ] as const;
};

export const getSearchMembershipTypesQueryOptions = <
  TData = Awaited<ReturnType<typeof searchMembershipTypes>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  params?: SearchMembershipTypesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof searchMembershipTypes>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getSearchMembershipTypesQueryKey(tenantUuid, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof searchMembershipTypes>>
  > = ({ signal }) =>
    searchMembershipTypes(tenantUuid, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!tenantUuid,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof searchMembershipTypes>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SearchMembershipTypesQueryResult = NonNullable<
  Awaited<ReturnType<typeof searchMembershipTypes>>
>;
export type SearchMembershipTypesQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Search Membership Types
 */
export const useSearchMembershipTypes = <
  TData = Awaited<ReturnType<typeof searchMembershipTypes>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  params?: SearchMembershipTypesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof searchMembershipTypes>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSearchMembershipTypesQueryOptions(
    tenantUuid,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

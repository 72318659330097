/**
 * Generated by orval v6.25.0 🍺
 * Do not edit manually.
 * UpSmith Staging API
 * OpenAPI spec version: 1.0.0
 */

export type ImagePurpose = (typeof ImagePurpose)[keyof typeof ImagePurpose];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ImagePurpose = {
  competition_cover: "competition_cover",
  competition_prize: "competition_prize",
  profile: "profile",
} as const;

/**
 * Generated by orval v6.25.0 🍺
 * Do not edit manually.
 * UpSmith Staging API
 * OpenAPI spec version: 1.0.0
 */

export type SkuSoldTriggerConfigSchemaTriggerType =
  (typeof SkuSoldTriggerConfigSchemaTriggerType)[keyof typeof SkuSoldTriggerConfigSchemaTriggerType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SkuSoldTriggerConfigSchemaTriggerType = {
  sku_sold: "sku_sold",
} as const;

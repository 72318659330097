/**
 * Generated by orval v6.25.0 🍺
 * Do not edit manually.
 * UpSmith Staging API
 * OpenAPI spec version: 1.0.0
 */

export type BoostModuleAboutName =
  (typeof BoostModuleAboutName)[keyof typeof BoostModuleAboutName];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BoostModuleAboutName = {
  about: "about",
} as const;

import * as React from "react";
import { Controller } from "react-hook-form";
import { NumberFormatBase, usePatternFormat } from "react-number-format";

import { InputErrorMessage } from "@/components/inputs/InputErrorMessage";
import { TextInput } from "@/components/inputs/TextInput";

// This component is made to be used within a react-hook-form provider

export const PhoneFormInput = ({
  name,
  label,
  required,
}: {
  name: string;
  label?: string;
  required?: boolean;
}) => {
  const { format, ...patternFormatProps } = usePatternFormat({
    format: "+1 (###) ###-####",
    allowEmptyFormatting: true,
  });

  const phoneNumberFormat = (val?: string) => {
    if (val) {
      // strip backend formatting if it exists
      return val.startsWith("+1") ? format(val.replace("+1", "")) : format(val);
    }
    return "";
  };

  return (
    <div className="flex flex-col text-left">
      {label && <label htmlFor="phone">{label}</label>}
      <Controller
        rules={required ? { required: "Phone number is required" } : {}}
        render={({
          field: { onChange, value, onBlur },
          fieldState: { error },
        }) => {
          return (
            <>
              <NumberFormatBase
                {...patternFormatProps}
                customInput={TextInput}
                valueIsNumericString
                value={value}
                onBlur={onBlur}
                onValueChange={(values) => {
                  // pass unformatted value into form
                  onChange(values.value);
                }}
                format={phoneNumberFormat}
                name={name}
              />
              <InputErrorMessage error={error?.message} />
            </>
          );
        }}
        name={name}
      />
    </div>
  );
};

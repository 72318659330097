import { useEffect } from "react";

export const useSetVariablesGlobally = (
  globalProperties: React.CSSProperties
) => {
  // Rather than just setting these variables for the component and its children,
  // we set them globally so that they can impact root styles (e.g. text color)
  useEffect(() => {
    const root = document.documentElement;
    Object.entries(globalProperties).forEach(([key, value]) => {
      root.style.setProperty(key, value);
    });
  }, [globalProperties]);
};

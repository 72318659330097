import React from "react";

import { ThemedLogo } from "@/lib/orgConfig";

import { UnstyledLink } from "@/components/links/UnstyledLink";

type Props = {
  children?: React.ReactNode;
};

export const BaseHeader: React.FC<Props> = ({ children }) => {
  return (
    <div className="relative z-20 hidden h-[70px] flex-row items-center justify-between border-b border-bordercolor bg-white px-5 shadow sm:flex">
      <UnstyledLink href="/">
        <ThemedLogo height={38} />
      </UnstyledLink>
      {children}
    </div>
  );
};

/**
 * Generated by orval v6.25.0 🍺
 * Do not edit manually.
 * UpSmith Staging API
 * OpenAPI spec version: 1.0.0
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import type {
  BoostUserAchievementSchema,
  GetBoostAchievementsSchema,
  GetBoostUserAchievementsSchema,
  HTTPValidationError,
} from ".././schema";
import { axiosInstance } from "../../lib/axios";
import type { ErrorType } from "../../lib/axios";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Get Current User Achievements
 */
export const getCurrentUserAchievements = (
  tenantUuid: string,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<GetBoostUserAchievementsSchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/users/me/achievements`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getGetCurrentUserAchievementsQueryKey = (tenantUuid: string) => {
  return [`/api/v1/tenants/${tenantUuid}/boost/users/me/achievements`] as const;
};

export const getGetCurrentUserAchievementsQueryOptions = <
  TData = Awaited<ReturnType<typeof getCurrentUserAchievements>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCurrentUserAchievements>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetCurrentUserAchievementsQueryKey(tenantUuid);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCurrentUserAchievements>>
  > = ({ signal }) =>
    getCurrentUserAchievements(tenantUuid, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!tenantUuid,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCurrentUserAchievements>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCurrentUserAchievementsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCurrentUserAchievements>>
>;
export type GetCurrentUserAchievementsQueryError =
  ErrorType<HTTPValidationError>;

/**
 * @summary Get Current User Achievements
 */
export const useGetCurrentUserAchievements = <
  TData = Awaited<ReturnType<typeof getCurrentUserAchievements>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCurrentUserAchievements>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCurrentUserAchievementsQueryOptions(
    tenantUuid,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get Achievements For User
 */
export const getAchievementsForUser = (
  tenantUuid: string,
  userUuid: string,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<GetBoostUserAchievementsSchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/users/${userUuid}/achievements`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getGetAchievementsForUserQueryKey = (
  tenantUuid: string,
  userUuid: string
) => {
  return [
    `/api/v1/tenants/${tenantUuid}/boost/users/${userUuid}/achievements`,
  ] as const;
};

export const getGetAchievementsForUserQueryOptions = <
  TData = Awaited<ReturnType<typeof getAchievementsForUser>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  userUuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getAchievementsForUser>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetAchievementsForUserQueryKey(tenantUuid, userUuid);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getAchievementsForUser>>
  > = ({ signal }) =>
    getAchievementsForUser(tenantUuid, userUuid, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(tenantUuid && userUuid),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getAchievementsForUser>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAchievementsForUserQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAchievementsForUser>>
>;
export type GetAchievementsForUserQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Get Achievements For User
 */
export const useGetAchievementsForUser = <
  TData = Awaited<ReturnType<typeof getAchievementsForUser>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  userUuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getAchievementsForUser>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetAchievementsForUserQueryOptions(
    tenantUuid,
    userUuid,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Award Achievement
 */
export const awardAchievement = (
  tenantUuid: string,
  userUuid: string,
  achievementUuid: string,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<BoostUserAchievementSchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/users/${userUuid}/achievements/${achievementUuid}/actions/award`,
      method: "POST",
    },
    options
  );
};

export const getAwardAchievementMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof awardAchievement>>,
    TError,
    { tenantUuid: string; userUuid: string; achievementUuid: string },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof awardAchievement>>,
  TError,
  { tenantUuid: string; userUuid: string; achievementUuid: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof awardAchievement>>,
    { tenantUuid: string; userUuid: string; achievementUuid: string }
  > = (props) => {
    const { tenantUuid, userUuid, achievementUuid } = props ?? {};

    return awardAchievement(
      tenantUuid,
      userUuid,
      achievementUuid,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type AwardAchievementMutationResult = NonNullable<
  Awaited<ReturnType<typeof awardAchievement>>
>;

export type AwardAchievementMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Award Achievement
 */
export const useAwardAchievement = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof awardAchievement>>,
    TError,
    { tenantUuid: string; userUuid: string; achievementUuid: string },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions = getAwardAchievementMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get Achievements
 */
export const getAchievements = (
  tenantUuid: string,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<GetBoostAchievementsSchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/achievements`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getGetAchievementsQueryKey = (tenantUuid: string) => {
  return [`/api/v1/tenants/${tenantUuid}/boost/achievements`] as const;
};

export const getGetAchievementsQueryOptions = <
  TData = Awaited<ReturnType<typeof getAchievements>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getAchievements>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetAchievementsQueryKey(tenantUuid);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAchievements>>> = ({
    signal,
  }) => getAchievements(tenantUuid, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!tenantUuid,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getAchievements>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAchievementsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAchievements>>
>;
export type GetAchievementsQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Get Achievements
 */
export const useGetAchievements = <
  TData = Awaited<ReturnType<typeof getAchievements>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getAchievements>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetAchievementsQueryOptions(tenantUuid, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

import { useCallback } from "react";

import { OrgModuleService } from "./types";

export const useIsModuleEnabled = (
  getModuleConfigIfEnabled: OrgModuleService["getModuleConfigIfEnabled"]
): OrgModuleService["isModuleEnabled"] => {
  return useCallback(
    (moduleName) => {
      return Boolean(getModuleConfigIfEnabled(moduleName));
    },
    [getModuleConfigIfEnabled]
  );
};

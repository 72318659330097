export const GenericErrorPage = () => {
  return (
    <div className="mt-10 flex flex-col items-center">
      <h3>Something went wrong</h3>
      <div className="text-center">
        We're aware of the issue and working to fix it. Please try{" "}
        <span
          className="cursor-pointer underline"
          onClick={() => {
            window.location.reload();
          }}
        >
          reloading the page
        </span>{" "}
        or{" "}
        <span
          className="cursor-pointer underline"
          onClick={() => {
            // Use window to hard reload to clear the error state
            window.location.href = "/";
          }}
        >
          returning home
        </span>{" "}
        to continue.
      </div>
    </div>
  );
};

/**
 * Generated by orval v6.25.0 🍺
 * Do not edit manually.
 * UpSmith Staging API
 * OpenAPI spec version: 1.0.0
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import type {
  DeleteNotification200,
  GetAllNotificationsParams,
  HTTPValidationError,
  NotificationListSchema,
  NotificationSchema,
} from ".././schema";
import { axiosInstance } from "../../lib/axios";
import type { ErrorType } from "../../lib/axios";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Get All Notifications
 */
export const getAllNotifications = (
  tenantUuid: string,
  params?: GetAllNotificationsParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<NotificationListSchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/notifications`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getGetAllNotificationsQueryKey = (
  tenantUuid: string,
  params?: GetAllNotificationsParams
) => {
  return [
    `/api/v1/tenants/${tenantUuid}/boost/notifications`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetAllNotificationsQueryOptions = <
  TData = Awaited<ReturnType<typeof getAllNotifications>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  params?: GetAllNotificationsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getAllNotifications>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetAllNotificationsQueryKey(tenantUuid, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getAllNotifications>>
  > = ({ signal }) =>
    getAllNotifications(tenantUuid, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!tenantUuid,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getAllNotifications>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAllNotificationsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAllNotifications>>
>;
export type GetAllNotificationsQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Get All Notifications
 */
export const useGetAllNotifications = <
  TData = Awaited<ReturnType<typeof getAllNotifications>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  params?: GetAllNotificationsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getAllNotifications>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetAllNotificationsQueryOptions(
    tenantUuid,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get Notification By Uuid
 */
export const getNotificationByUuid = (
  tenantUuid: string,
  notificationUuid: string,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<NotificationSchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/notifications/${notificationUuid}`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getGetNotificationByUuidQueryKey = (
  tenantUuid: string,
  notificationUuid: string
) => {
  return [
    `/api/v1/tenants/${tenantUuid}/boost/notifications/${notificationUuid}`,
  ] as const;
};

export const getGetNotificationByUuidQueryOptions = <
  TData = Awaited<ReturnType<typeof getNotificationByUuid>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  notificationUuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getNotificationByUuid>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetNotificationByUuidQueryKey(tenantUuid, notificationUuid);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getNotificationByUuid>>
  > = ({ signal }) =>
    getNotificationByUuid(tenantUuid, notificationUuid, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(tenantUuid && notificationUuid),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getNotificationByUuid>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetNotificationByUuidQueryResult = NonNullable<
  Awaited<ReturnType<typeof getNotificationByUuid>>
>;
export type GetNotificationByUuidQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Get Notification By Uuid
 */
export const useGetNotificationByUuid = <
  TData = Awaited<ReturnType<typeof getNotificationByUuid>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  notificationUuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getNotificationByUuid>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetNotificationByUuidQueryOptions(
    tenantUuid,
    notificationUuid,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Delete Notification
 */
export const deleteNotification = (
  tenantUuid: string,
  notificationUuid: string,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<DeleteNotification200>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/notifications/${notificationUuid}`,
      method: "DELETE",
    },
    options
  );
};

export const getDeleteNotificationMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteNotification>>,
    TError,
    { tenantUuid: string; notificationUuid: string },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteNotification>>,
  TError,
  { tenantUuid: string; notificationUuid: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteNotification>>,
    { tenantUuid: string; notificationUuid: string }
  > = (props) => {
    const { tenantUuid, notificationUuid } = props ?? {};

    return deleteNotification(tenantUuid, notificationUuid, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteNotificationMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteNotification>>
>;

export type DeleteNotificationMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Delete Notification
 */
export const useDeleteNotification = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteNotification>>,
    TError,
    { tenantUuid: string; notificationUuid: string },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions = getDeleteNotificationMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Mark Notification As Read
 */
export const markNotificationAsRead = (
  tenantUuid: string,
  notificationUuid: string,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<NotificationSchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/notifications/${notificationUuid}/actions/mark-as-read`,
      method: "PUT",
    },
    options
  );
};

export const getMarkNotificationAsReadMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof markNotificationAsRead>>,
    TError,
    { tenantUuid: string; notificationUuid: string },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof markNotificationAsRead>>,
  TError,
  { tenantUuid: string; notificationUuid: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof markNotificationAsRead>>,
    { tenantUuid: string; notificationUuid: string }
  > = (props) => {
    const { tenantUuid, notificationUuid } = props ?? {};

    return markNotificationAsRead(tenantUuid, notificationUuid, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type MarkNotificationAsReadMutationResult = NonNullable<
  Awaited<ReturnType<typeof markNotificationAsRead>>
>;

export type MarkNotificationAsReadMutationError =
  ErrorType<HTTPValidationError>;

/**
 * @summary Mark Notification As Read
 */
export const useMarkNotificationAsRead = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof markNotificationAsRead>>,
    TError,
    { tenantUuid: string; notificationUuid: string },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions = getMarkNotificationAsReadMutationOptions(options);

  return useMutation(mutationOptions);
};

import { useCallback } from "react";

import { OrgModuleService } from "./types";

export const useGetModuleConfigIfEnabled = (
  getModuleConfig: OrgModuleService["getModuleConfig"]
): OrgModuleService["getModuleConfigIfEnabled"] => {
  return useCallback(
    (moduleName) => {
      const moduleConfig = getModuleConfig(moduleName);
      if (moduleConfig && moduleConfig.is_enabled) {
        return moduleConfig;
      }
      return null;
    },
    [getModuleConfig]
  );
};

/**
 * Generated by orval v6.25.0 🍺
 * Do not edit manually.
 * UpSmith Staging API
 * OpenAPI spec version: 1.0.0
 */

export type BoostOnboardAdminTechnicianUserDataRole =
  (typeof BoostOnboardAdminTechnicianUserDataRole)[keyof typeof BoostOnboardAdminTechnicianUserDataRole];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BoostOnboardAdminTechnicianUserDataRole = {
  admin_technician: "admin_technician",
} as const;

import Image from "next/image";
import { useContext } from "react";

import { Logo } from "@/components/Logo";

import { OrgConfigContext } from "./OrgConfigProvider";

type Props = {
  height: number;
};

export const ThemedLogo = ({ height }: Props) => {
  const orgConfigContext = useContext(OrgConfigContext);

  if (orgConfigContext && orgConfigContext.logoUrl) {
    return (
      // Allow image to scale based on its aspect ratio, constrained by the given height
      <Image
        src={orgConfigContext.logoUrl}
        alt="Logo"
        width={0}
        height={0}
        sizes="100vw"
        style={{ width: "auto", height: `${height}px` }}
      />
    );
  }

  return <Logo height={height} />;
};

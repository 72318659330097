/**
 * Generated by orval v6.25.0 🍺
 * Do not edit manually.
 * UpSmith Staging API
 * OpenAPI spec version: 1.0.0
 */
import { useMutation } from "@tanstack/react-query";
import type {
  MutationFunction,
  UseMutationOptions,
} from "@tanstack/react-query";
import type {
  BoostPointTransactionCreateInput,
  BoostPointTransactionSearchInput,
  BoostPointTransactionSearchResult,
  CreatePointTransaction201,
  HTTPValidationError,
} from ".././schema";
import { axiosInstance } from "../../lib/axios";
import type { ErrorType } from "../../lib/axios";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Boost Search Point Transactions
 */
export const boostSearchPointTransactions = (
  tenantUuid: string,
  boostPointTransactionSearchInput: BoostPointTransactionSearchInput,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<BoostPointTransactionSearchResult>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/point-transactions/search`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: boostPointTransactionSearchInput,
    },
    options
  );
};

export const getBoostSearchPointTransactionsMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof boostSearchPointTransactions>>,
    TError,
    { tenantUuid: string; data: BoostPointTransactionSearchInput },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof boostSearchPointTransactions>>,
  TError,
  { tenantUuid: string; data: BoostPointTransactionSearchInput },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof boostSearchPointTransactions>>,
    { tenantUuid: string; data: BoostPointTransactionSearchInput }
  > = (props) => {
    const { tenantUuid, data } = props ?? {};

    return boostSearchPointTransactions(tenantUuid, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type BoostSearchPointTransactionsMutationResult = NonNullable<
  Awaited<ReturnType<typeof boostSearchPointTransactions>>
>;
export type BoostSearchPointTransactionsMutationBody =
  BoostPointTransactionSearchInput;
export type BoostSearchPointTransactionsMutationError =
  ErrorType<HTTPValidationError>;

/**
 * @summary Boost Search Point Transactions
 */
export const useBoostSearchPointTransactions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof boostSearchPointTransactions>>,
    TError,
    { tenantUuid: string; data: BoostPointTransactionSearchInput },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getBoostSearchPointTransactionsMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Create Point Transaction
 */
export const createPointTransaction = (
  tenantUuid: string,
  boostPointTransactionCreateInput: BoostPointTransactionCreateInput[],
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<CreatePointTransaction201>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/point-transactions`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: boostPointTransactionCreateInput,
    },
    options
  );
};

export const getCreatePointTransactionMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createPointTransaction>>,
    TError,
    { tenantUuid: string; data: BoostPointTransactionCreateInput[] },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createPointTransaction>>,
  TError,
  { tenantUuid: string; data: BoostPointTransactionCreateInput[] },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createPointTransaction>>,
    { tenantUuid: string; data: BoostPointTransactionCreateInput[] }
  > = (props) => {
    const { tenantUuid, data } = props ?? {};

    return createPointTransaction(tenantUuid, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreatePointTransactionMutationResult = NonNullable<
  Awaited<ReturnType<typeof createPointTransaction>>
>;
export type CreatePointTransactionMutationBody =
  BoostPointTransactionCreateInput[];
export type CreatePointTransactionMutationError =
  ErrorType<HTTPValidationError>;

/**
 * @summary Create Point Transaction
 */
export const useCreatePointTransaction = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createPointTransaction>>,
    TError,
    { tenantUuid: string; data: BoostPointTransactionCreateInput[] },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions = getCreatePointTransactionMutationOptions(options);

  return useMutation(mutationOptions);
};

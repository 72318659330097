/**
 * Generated by orval v6.25.0 🍺
 * Do not edit manually.
 * UpSmith Staging API
 * OpenAPI spec version: 1.0.0
 */

export type RaffleEntryType =
  (typeof RaffleEntryType)[keyof typeof RaffleEntryType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RaffleEntryType = {
  competition: "competition",
} as const;

/**
 * Generated by orval v6.25.0 🍺
 * Do not edit manually.
 * UpSmith Staging API
 * OpenAPI spec version: 1.0.0
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import type {
  BoostAccountBalanceSchema,
  BoostRedeemPointsInput,
  HTTPValidationError,
} from ".././schema";
import { axiosInstance } from "../../lib/axios";
import type { ErrorType } from "../../lib/axios";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Boost Get Account Balance
 */
export const boostGetAccountBalance = (
  tenantUuid: string,
  accountUuid: string,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<BoostAccountBalanceSchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/accounts/${accountUuid}/balance`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getBoostGetAccountBalanceQueryKey = (
  tenantUuid: string,
  accountUuid: string
) => {
  return [
    `/api/v1/tenants/${tenantUuid}/boost/accounts/${accountUuid}/balance`,
  ] as const;
};

export const getBoostGetAccountBalanceQueryOptions = <
  TData = Awaited<ReturnType<typeof boostGetAccountBalance>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  accountUuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof boostGetAccountBalance>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getBoostGetAccountBalanceQueryKey(tenantUuid, accountUuid);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof boostGetAccountBalance>>
  > = ({ signal }) =>
    boostGetAccountBalance(tenantUuid, accountUuid, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(tenantUuid && accountUuid),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof boostGetAccountBalance>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type BoostGetAccountBalanceQueryResult = NonNullable<
  Awaited<ReturnType<typeof boostGetAccountBalance>>
>;
export type BoostGetAccountBalanceQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Boost Get Account Balance
 */
export const useBoostGetAccountBalance = <
  TData = Awaited<ReturnType<typeof boostGetAccountBalance>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  accountUuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof boostGetAccountBalance>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getBoostGetAccountBalanceQueryOptions(
    tenantUuid,
    accountUuid,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Boost Redeem Points
 */
export const boostRedeemPoints = (
  tenantUuid: string,
  accountUuid: string,
  boostRedeemPointsInput: BoostRedeemPointsInput,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<BoostAccountBalanceSchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/accounts/${accountUuid}/actions/redeem`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: boostRedeemPointsInput,
    },
    options
  );
};

export const getBoostRedeemPointsMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof boostRedeemPoints>>,
    TError,
    { tenantUuid: string; accountUuid: string; data: BoostRedeemPointsInput },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof boostRedeemPoints>>,
  TError,
  { tenantUuid: string; accountUuid: string; data: BoostRedeemPointsInput },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof boostRedeemPoints>>,
    { tenantUuid: string; accountUuid: string; data: BoostRedeemPointsInput }
  > = (props) => {
    const { tenantUuid, accountUuid, data } = props ?? {};

    return boostRedeemPoints(tenantUuid, accountUuid, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type BoostRedeemPointsMutationResult = NonNullable<
  Awaited<ReturnType<typeof boostRedeemPoints>>
>;
export type BoostRedeemPointsMutationBody = BoostRedeemPointsInput;
export type BoostRedeemPointsMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Boost Redeem Points
 */
export const useBoostRedeemPoints = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof boostRedeemPoints>>,
    TError,
    { tenantUuid: string; accountUuid: string; data: BoostRedeemPointsInput },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions = getBoostRedeemPointsMutationOptions(options);

  return useMutation(mutationOptions);
};

import { createContext, useMemo, useState } from "react";

import { useTenantUuid } from "@/components/hooks/useTenantUuid";

import {
  TenantOrganizationDetailsSchema,
  useGetCurrentTenantOrganization,
} from "@/upsmith-api";

import { useGetVariablesForBrand } from "./useGetVariablesForBrand";
import { useSetVariablesGlobally } from "./useSetVariablesGlobally";
import { OrgConfig } from "../types";

export const OrgConfigContext = createContext<OrgConfig>({
  uuid: null,
  loaded: false,
  logoUrl: null,
  modules: [],
  translationMap: null,
});

export const OrgConfigProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [userOrg, setUserOrg] =
    useState<TenantOrganizationDetailsSchema | null>(null);

  const tenantUuid = useTenantUuid();

  useGetCurrentTenantOrganization(tenantUuid, {
    query: {
      onSuccess: (org) => {
        setUserOrg(org);
      },
    },
  });

  const orgConfig: OrgConfig = useMemo(() => {
    return {
      uuid: userOrg?.uuid || null,
      loaded: !!userOrg,
      logoUrl: userOrg?.properties?.brand_properties?.logo_url || null,
      modules: userOrg?.modules || [],
      translationMap: userOrg?.properties?.translation_map || null,
    };
  }, [userOrg]);

  const brandVariables = useGetVariablesForBrand(
    userOrg?.properties?.brand_properties || null
  );
  useSetVariablesGlobally(brandVariables);

  return (
    <OrgConfigContext.Provider value={orgConfig}>
      {children}
    </OrgConfigContext.Provider>
  );
};

/**
 * Generated by orval v6.25.0 🍺
 * Do not edit manually.
 * UpSmith Staging API
 * OpenAPI spec version: 1.0.0
 */
import { useQuery } from "@tanstack/react-query";
import type {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import type { BoostRewardsSchema, HTTPValidationError } from ".././schema";
import { axiosInstance } from "../../lib/axios";
import type { ErrorType } from "../../lib/axios";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Get Rewards
 */
export const getRewards = (
  tenantUuid: string,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<BoostRewardsSchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/rewards`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getGetRewardsQueryKey = (tenantUuid: string) => {
  return [`/api/v1/tenants/${tenantUuid}/boost/rewards`] as const;
};

export const getGetRewardsQueryOptions = <
  TData = Awaited<ReturnType<typeof getRewards>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getRewards>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetRewardsQueryKey(tenantUuid);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getRewards>>> = ({
    signal,
  }) => getRewards(tenantUuid, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!tenantUuid,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getRewards>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetRewardsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getRewards>>
>;
export type GetRewardsQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Get Rewards
 */
export const useGetRewards = <
  TData = Awaited<ReturnType<typeof getRewards>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getRewards>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetRewardsQueryOptions(tenantUuid, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

import { useMemo } from "react";

import { BrandProperties, BrandTheme } from "@/upsmith-api";

import { GlobalBrandProperty } from "./types";

export const useGetVariablesForBrand = (
  brandProperties: BrandProperties | null
): GlobalBrandProperty => {
  return useMemo((): GlobalBrandProperty => {
    if (!brandProperties || !brandProperties.theme) {
      return {};
    }

    // If the amount of themes we need to support grows too large, these style overrides
    // should me moved into a config the lives in the database.
    if (brandProperties.theme === BrandTheme.default) {
      return {};
    }

    if (brandProperties.theme === BrandTheme.pink) {
      return {
        // table rows
        "--primary-color-50": "#f1dbe8",

        // disclosure item bg
        "--primary-color-100": "#f8d3e8",

        // Text button, switch, primary link
        "--primary-color-200": "#f0bbda",

        // Disclosure chevron, tooltip, header links, primary buttons
        "--primary-color-500": "#e31c93",

        // Primary button border + hover
        "--primary-color-600": "#cb1181",

        // primary links
        "--primary-color-700": "#ab096a",

        // h1, h2, footer background
        "--primary-color-800": "#005cb9",
      };
    }

    const exhaustiveCheck: never = brandProperties.theme;
    return exhaustiveCheck;
  }, [brandProperties]);
};

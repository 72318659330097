import { clsxm } from "@/lib/clsxm";

type Props = {
  borderClass: string;
  children: React.ReactNode;
};

export const DividerText = ({ borderClass, children }: Props) => {
  return (
    <div className="relative flex items-center py-5">
      <div className={clsxm("grow border-t", borderClass)}></div>
      <span className="mx-4 shrink">{children}</span>
      <div className={clsxm("grow border-t", borderClass)}></div>
    </div>
  );
};

/**
 * Generated by orval v6.25.0 🍺
 * Do not edit manually.
 * UpSmith Staging API
 * OpenAPI spec version: 1.0.0
 */

export type MembershipSoldHandlerConfigSchemaHandlerType =
  (typeof MembershipSoldHandlerConfigSchemaHandlerType)[keyof typeof MembershipSoldHandlerConfigSchemaHandlerType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MembershipSoldHandlerConfigSchemaHandlerType = {
  membership_sold_points: "membership_sold_points",
  membership_sold_competition: "membership_sold_competition",
} as const;

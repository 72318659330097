import { clsxm } from "@/lib/clsxm";

export function InputErrorMessage({
  error,
  className,
}: {
  error?: string;
  className?: string;
}) {
  return (
    <>
      {error && (
        <p className={clsxm(className, "mt-0.5 text-xs text-danger-500")}>
          {error}
        </p>
      )}
    </>
  );
}

import Image from "next/image";

type Props =
  | {
      height?: number;
      width: number;
    }
  | {
      height: number;
      width?: number;
    };

export const Logo = ({ height, width }: Props) => {
  const aspectRatio = 164 / 40;

  let inferredHeight = 0;
  if (!height && width) {
    inferredHeight = width / aspectRatio;
  }

  let inferredWidth = 0;
  if (!width && height) {
    inferredWidth = height * aspectRatio;
  }

  return (
    <Image
      src="/images/logo.svg"
      alt="UpSmith Candidate Logo"
      height={height || inferredHeight}
      width={width || inferredWidth}
    />
  );
};

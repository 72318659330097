/**
 * Generated by orval v6.25.0 🍺
 * Do not edit manually.
 * UpSmith Staging API
 * OpenAPI spec version: 1.0.0
 */

export type BoostPointTransactionType =
  (typeof BoostPointTransactionType)[keyof typeof BoostPointTransactionType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BoostPointTransactionType = {
  earn: "earn",
  redeem: "redeem",
  grant: "grant",
  adjustment: "adjustment",
  raffle_win: "raffle_win",
} as const;

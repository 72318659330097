/**
 * Generated by orval v6.25.0 🍺
 * Do not edit manually.
 * UpSmith Staging API
 * OpenAPI spec version: 1.0.0
 */

export type CompetitionStateFeedItemSchemaType =
  (typeof CompetitionStateFeedItemSchemaType)[keyof typeof CompetitionStateFeedItemSchemaType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompetitionStateFeedItemSchemaType = {
  competition_state: "competition_state",
} as const;

import { RedirectToLogin } from "@propelauth/react";
import { AxiosError } from "axios";
import * as React from "react";
import { isMobile } from "react-device-detect";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { usePatternFormat } from "react-number-format";

import { clsxm } from "@/lib/clsxm";

import { Button } from "@/components/buttons/Button";
import { TextButton } from "@/components/buttons/TextButton";
import { DividerText } from "@/components/DividerText";
import { useToggle } from "@/components/hooks/useToggle";
import { PhoneFormInput } from "@/components/inputs/PhoneInput";
import { TextInput } from "@/components/inputs/TextInput";
import { LoggedOutHeader } from "@/components/layout/Header/LoggedOutHeader";

import { isLocal, upsmithApiKey } from "@/constant/env";
import { useSendMagicLinkEmail, useSendMagicLinkSms } from "@/upsmith-api";

export type SMSLoginFormValues = {
  phone?: string;
};

export type EmailLoginFormValues = {
  email?: string;
};

const getPhoneLoginErrorDisplayMessage = (error: AxiosError) => {
  const statusCode = error?.response?.status;
  if (statusCode === 422) {
    return "Phone number is invalid";
  }
  if (statusCode === 401) {
    return "No user found with that phone number.";
  }

  return "There was an error logging in. Please try again later.";
};

const getEmailLoginErrorDisplayMessage = (error: AxiosError) => {
  const statusCode = error?.response?.status;
  if (statusCode === 422) {
    return "Email is invalid";
  }
  if (statusCode === 401) {
    return "No user found with that email.";
  }

  return "There was an error logging in. Please try again later.";
};

function SmsLogin({
  isMagicLinkSent,
  onMagicLinkSent,
}: {
  isMagicLinkSent: boolean;
  onMagicLinkSent: () => void;
}) {
  const formMethods = useForm<SMSLoginFormValues>({
    mode: "onTouched",
  });
  const { handleSubmit, formState, register, watch } = formMethods;

  const isFormValid = !!formState.dirtyFields.phone;

  const {
    mutate: sendMagicLinkSMS,
    error: smsError,
    isLoading,
  } = useSendMagicLinkSms({
    request: {
      headers: {
        "x-api-key": upsmithApiKey,
      },
    },
    mutation: {
      onSuccess: () => {
        onMagicLinkSent();
      },
    },
  });

  const onSMSSubmit: SubmitHandler<SMSLoginFormValues> = React.useCallback(
    (formData) => {
      if (formData?.phone) {
        sendMagicLinkSMS({
          data: {
            phone: formData.phone,
            redirect_url: window.location.href,
          },
        });
      }
    },
    [sendMagicLinkSMS]
  );

  const { format } = usePatternFormat({
    format: "+1 (###) ###-####",
    allowEmptyFormatting: true,
  });

  const phoneState = watch("phone");

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSMSSubmit)}>
        {isMagicLinkSent && (
          <section>
            <div>
              <h1 className="mb-4 text-3xl text-primary-700">
                Magic Link Sent! 🪄
              </h1>
              {phoneState ? (
                <h4>
                  We just sent you a text message at{" "}
                  <b className="inline-block">{format(phoneState)}</b>. It
                  contains a link that will log you in to UpSmith.
                </h4>
              ) : (
                <h4>
                  We just sent you a text message. It contains a link that will
                  log you in to UpSmith.
                </h4>
              )}
            </div>
            <p className="mt-4">
              Message not received? &nbsp;
              <TextButton type="submit">Resend Link</TextButton>
            </p>
          </section>
        )}
        <div className={clsxm(isMagicLinkSent ? "hidden" : "relative")}>
          <div className="flex flex-col gap-y-4">
            <div>
              <h1 className="mb-4 text-3xl text-primary-700">
                Login to UpSmith
              </h1>
              <h4>
                Enter your phone number and we will text a login link to your
                phone.
              </h4>
            </div>
            <PhoneFormInput {...register("phone")} />
            {!!smsError && (
              <p className=" text-red-500">
                {getPhoneLoginErrorDisplayMessage(smsError)}
              </p>
            )}
            <Button
              type="submit"
              size="lg"
              rounded
              fullWidth
              isLoading={isLoading}
              disabled={!isFormValid || isLoading}
            >
              Send Link to Phone
            </Button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
}

function EmailLogin({
  isMagicLinkSent,
  onMagicLinkSent,
}: {
  isMagicLinkSent: boolean;
  onMagicLinkSent: () => void;
}) {
  const formMethods = useForm<EmailLoginFormValues>({
    mode: "onTouched",
  });
  const { handleSubmit, formState, register, watch } = formMethods;

  const isFormValid = !!formState.dirtyFields.email;

  const {
    mutate: sendMagicLinkEmail,
    error: emailError,
    isLoading,
  } = useSendMagicLinkEmail({
    request: {
      headers: {
        "x-api-key": upsmithApiKey,
      },
    },
    mutation: {
      onSuccess: () => {
        onMagicLinkSent();
      },
    },
  });

  const onEmailSubmit: SubmitHandler<EmailLoginFormValues> = React.useCallback(
    (formData) => {
      if (formData?.email) {
        sendMagicLinkEmail({
          data: {
            email: formData.email,
            redirect_url: window.location.href,
          },
        });
      }
    },
    [sendMagicLinkEmail]
  );

  const emailState = watch("email");

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onEmailSubmit)}>
        {isMagicLinkSent && (
          <section>
            <div>
              <h1 className="mb-4 text-3xl text-primary-700">
                Magic Link Sent! 🪄
              </h1>
              {emailState ? (
                <h4>
                  We just sent you an email at{" "}
                  <b className="inline-block">{emailState}</b>. It contains a
                  link that will log you in to UpSmith.
                </h4>
              ) : (
                <h4>
                  We just sent you an email. It contains a link that will log
                  you in to UpSmith.
                </h4>
              )}
            </div>
            <p className="mt-4">
              Message not received? &nbsp;
              <TextButton type="submit">Resend Link</TextButton>
            </p>
          </section>
        )}
        <div className={clsxm(isMagicLinkSent ? "hidden" : "relative")}>
          <div className="flex flex-col gap-y-4">
            <div>
              <h1 className="mb-4 text-3xl text-primary-700">
                Login to UpSmith
              </h1>
              <h4>Enter your email and we will send a login link.</h4>
            </div>
            <TextInput {...register("email")} />
            {!!emailError && (
              <p className="text-red-500">
                {getEmailLoginErrorDisplayMessage(emailError)}
              </p>
            )}
            <Button
              type="submit"
              size="lg"
              rounded
              fullWidth
              isLoading={isLoading}
              disabled={!isFormValid || isLoading}
            >
              Send Link to Email
            </Button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
}

export default function LoginPage() {
  const [showEmailLogin, toggleLoginType] = useToggle(!isMobile);
  const [isMagicLinkSent, setIsMagicLinkSent] = React.useState(false);
  const [showPasswordLogin, toggleShowPasswordLogin] = useToggle(false);

  if (isLocal && showPasswordLogin) {
    return <RedirectToLogin postLoginRedirectUrl={origin} />;
  }
  return (
    <div className="bg-gray-50 antialiased">
      <LoggedOutHeader />
      <main className="layout m-4 mx-auto min-h-screen md:mt-8">
        <div className="flex min-h-screen flex-col items-center py-12 sm:px-6 lg:px-8">
          <div className="w-full max-w-md rounded-xl bg-white p-4 text-center">
            {showEmailLogin ? (
              <EmailLogin
                isMagicLinkSent={isMagicLinkSent}
                onMagicLinkSent={() => {
                  return setIsMagicLinkSent(true);
                }}
              />
            ) : (
              <SmsLogin
                isMagicLinkSent={isMagicLinkSent}
                onMagicLinkSent={() => {
                  return setIsMagicLinkSent(true);
                }}
              />
            )}
            {!isMagicLinkSent ? (
              <>
                <DividerText borderClass="border-gray-200">OR</DividerText>
                <div>
                  <Button
                    fullWidth
                    size="lg"
                    rounded
                    variant="outline"
                    onClick={toggleLoginType}
                  >
                    {showEmailLogin
                      ? "Login via phone number"
                      : "Login via email"}
                  </Button>
                </div>
              </>
            ) : null}
            {isLocal && !isMagicLinkSent && (
              <Button
                className="mt-2"
                size="lg"
                fullWidth
                variant="outline"
                rounded
                onClick={toggleShowPasswordLogin}
              >
                Login with Password
              </Button>
            )}
          </div>
        </div>
      </main>
    </div>
  );
}

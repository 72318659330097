/**
 * Generated by orval v6.25.0 🍺
 * Do not edit manually.
 * UpSmith Staging API
 * OpenAPI spec version: 1.0.0
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import type {
  BoostSearchTeamResultSchema,
  HTTPValidationError,
  SearchTeamsParams,
  TeamSchema,
  TeamUpsertSchema,
} from ".././schema";
import { axiosInstance } from "../../lib/axios";
import type { ErrorType } from "../../lib/axios";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Search Teams
 */
export const searchTeams = (
  tenantUuid: string,
  params?: SearchTeamsParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<BoostSearchTeamResultSchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/teams/search`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getSearchTeamsQueryKey = (
  tenantUuid: string,
  params?: SearchTeamsParams
) => {
  return [
    `/api/v1/tenants/${tenantUuid}/boost/teams/search`,
    ...(params ? [params] : []),
  ] as const;
};

export const getSearchTeamsQueryOptions = <
  TData = Awaited<ReturnType<typeof searchTeams>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  params?: SearchTeamsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof searchTeams>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getSearchTeamsQueryKey(tenantUuid, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof searchTeams>>> = ({
    signal,
  }) => searchTeams(tenantUuid, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!tenantUuid,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof searchTeams>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SearchTeamsQueryResult = NonNullable<
  Awaited<ReturnType<typeof searchTeams>>
>;
export type SearchTeamsQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Search Teams
 */
export const useSearchTeams = <
  TData = Awaited<ReturnType<typeof searchTeams>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  params?: SearchTeamsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof searchTeams>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSearchTeamsQueryOptions(tenantUuid, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Create Team
 */
export const createTeam = (
  tenantUuid: string,
  teamUpsertSchema: TeamUpsertSchema,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<TeamSchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/teams`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: teamUpsertSchema,
    },
    options
  );
};

export const getCreateTeamMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createTeam>>,
    TError,
    { tenantUuid: string; data: TeamUpsertSchema },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createTeam>>,
  TError,
  { tenantUuid: string; data: TeamUpsertSchema },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createTeam>>,
    { tenantUuid: string; data: TeamUpsertSchema }
  > = (props) => {
    const { tenantUuid, data } = props ?? {};

    return createTeam(tenantUuid, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateTeamMutationResult = NonNullable<
  Awaited<ReturnType<typeof createTeam>>
>;
export type CreateTeamMutationBody = TeamUpsertSchema;
export type CreateTeamMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Create Team
 */
export const useCreateTeam = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createTeam>>,
    TError,
    { tenantUuid: string; data: TeamUpsertSchema },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions = getCreateTeamMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get Team By Uuid
 */
export const getTeamByUuid = (
  tenantUuid: string,
  teamUuid: string,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<TeamSchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/teams/${teamUuid}`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getGetTeamByUuidQueryKey = (
  tenantUuid: string,
  teamUuid: string
) => {
  return [`/api/v1/tenants/${tenantUuid}/boost/teams/${teamUuid}`] as const;
};

export const getGetTeamByUuidQueryOptions = <
  TData = Awaited<ReturnType<typeof getTeamByUuid>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  teamUuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getTeamByUuid>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetTeamByUuidQueryKey(tenantUuid, teamUuid);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTeamByUuid>>> = ({
    signal,
  }) => getTeamByUuid(tenantUuid, teamUuid, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(tenantUuid && teamUuid),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getTeamByUuid>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetTeamByUuidQueryResult = NonNullable<
  Awaited<ReturnType<typeof getTeamByUuid>>
>;
export type GetTeamByUuidQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Get Team By Uuid
 */
export const useGetTeamByUuid = <
  TData = Awaited<ReturnType<typeof getTeamByUuid>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  teamUuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getTeamByUuid>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetTeamByUuidQueryOptions(
    tenantUuid,
    teamUuid,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Delete Team By Uuid
 */
export const deleteTeamByUuid = (
  tenantUuid: string,
  teamUuid: string,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<void>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/teams/${teamUuid}`,
      method: "DELETE",
    },
    options
  );
};

export const getDeleteTeamByUuidMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteTeamByUuid>>,
    TError,
    { tenantUuid: string; teamUuid: string },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteTeamByUuid>>,
  TError,
  { tenantUuid: string; teamUuid: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteTeamByUuid>>,
    { tenantUuid: string; teamUuid: string }
  > = (props) => {
    const { tenantUuid, teamUuid } = props ?? {};

    return deleteTeamByUuid(tenantUuid, teamUuid, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteTeamByUuidMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteTeamByUuid>>
>;

export type DeleteTeamByUuidMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Delete Team By Uuid
 */
export const useDeleteTeamByUuid = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteTeamByUuid>>,
    TError,
    { tenantUuid: string; teamUuid: string },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions = getDeleteTeamByUuidMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Update Team By Uuid
 */
export const updateTeamByUuid = (
  tenantUuid: string,
  teamUuid: string,
  teamUpsertSchema: TeamUpsertSchema,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<TeamSchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/teams/${teamUuid}`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: teamUpsertSchema,
    },
    options
  );
};

export const getUpdateTeamByUuidMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateTeamByUuid>>,
    TError,
    { tenantUuid: string; teamUuid: string; data: TeamUpsertSchema },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateTeamByUuid>>,
  TError,
  { tenantUuid: string; teamUuid: string; data: TeamUpsertSchema },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateTeamByUuid>>,
    { tenantUuid: string; teamUuid: string; data: TeamUpsertSchema }
  > = (props) => {
    const { tenantUuid, teamUuid, data } = props ?? {};

    return updateTeamByUuid(tenantUuid, teamUuid, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateTeamByUuidMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateTeamByUuid>>
>;
export type UpdateTeamByUuidMutationBody = TeamUpsertSchema;
export type UpdateTeamByUuidMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Update Team By Uuid
 */
export const useUpdateTeamByUuid = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateTeamByUuid>>,
    TError,
    { tenantUuid: string; teamUuid: string; data: TeamUpsertSchema },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions = getUpdateTeamByUuidMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Delete User From Team
 */
export const deleteUserFromTeam = (
  tenantUuid: string,
  teamUuid: string,
  userUuid: string,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<TeamSchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/teams/${teamUuid}/users/${userUuid}`,
      method: "DELETE",
    },
    options
  );
};

export const getDeleteUserFromTeamMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteUserFromTeam>>,
    TError,
    { tenantUuid: string; teamUuid: string; userUuid: string },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteUserFromTeam>>,
  TError,
  { tenantUuid: string; teamUuid: string; userUuid: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteUserFromTeam>>,
    { tenantUuid: string; teamUuid: string; userUuid: string }
  > = (props) => {
    const { tenantUuid, teamUuid, userUuid } = props ?? {};

    return deleteUserFromTeam(tenantUuid, teamUuid, userUuid, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteUserFromTeamMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteUserFromTeam>>
>;

export type DeleteUserFromTeamMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Delete User From Team
 */
export const useDeleteUserFromTeam = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteUserFromTeam>>,
    TError,
    { tenantUuid: string; teamUuid: string; userUuid: string },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions = getDeleteUserFromTeamMutationOptions(options);

  return useMutation(mutationOptions);
};

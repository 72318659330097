/**
 * Generated by orval v6.25.0 🍺
 * Do not edit manually.
 * UpSmith Staging API
 * OpenAPI spec version: 1.0.0
 */
import { useMutation } from "@tanstack/react-query";
import type {
  MutationFunction,
  UseMutationOptions,
} from "@tanstack/react-query";
import type {
  BoostAnnounceInput,
  BoostAnnounceResponse,
  HTTPValidationError,
} from ".././schema";
import { axiosInstance } from "../../lib/axios";
import type { ErrorType } from "../../lib/axios";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Boost Send Announcement
 */
export const boostSendAnnouncement = (
  tenantUuid: string,
  boostAnnounceInput: BoostAnnounceInput,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<BoostAnnounceResponse>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/announcements`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: boostAnnounceInput,
    },
    options
  );
};

export const getBoostSendAnnouncementMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof boostSendAnnouncement>>,
    TError,
    { tenantUuid: string; data: BoostAnnounceInput },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof boostSendAnnouncement>>,
  TError,
  { tenantUuid: string; data: BoostAnnounceInput },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof boostSendAnnouncement>>,
    { tenantUuid: string; data: BoostAnnounceInput }
  > = (props) => {
    const { tenantUuid, data } = props ?? {};

    return boostSendAnnouncement(tenantUuid, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type BoostSendAnnouncementMutationResult = NonNullable<
  Awaited<ReturnType<typeof boostSendAnnouncement>>
>;
export type BoostSendAnnouncementMutationBody = BoostAnnounceInput;
export type BoostSendAnnouncementMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Boost Send Announcement
 */
export const useBoostSendAnnouncement = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof boostSendAnnouncement>>,
    TError,
    { tenantUuid: string; data: BoostAnnounceInput },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions = getBoostSendAnnouncementMutationOptions(options);

  return useMutation(mutationOptions);
};

import { useContext } from "react";

import { OrgModuleService } from "./types";
import { useGetModuleConfig } from "./useGetModuleConfig";
import { useGetModuleConfigIfEnabled } from "./useGetModuleConfigIfEnabled";
import { useIsModuleEnabled } from "./useIsModuleEnabled";
import { OrgConfigContext } from "../OrgConfigProvider";

export const useOrgModuleService = (): OrgModuleService => {
  const orgContext = useContext(OrgConfigContext);

  const getModuleConfig = useGetModuleConfig(orgContext);

  const getModuleConfigIfEnabled = useGetModuleConfigIfEnabled(getModuleConfig);
  const isModuleEnabled = useIsModuleEnabled(getModuleConfigIfEnabled);

  return {
    getModuleConfig,
    getModuleConfigIfEnabled,
    isModuleEnabled,
    modulesLoaded: orgContext.loaded,
  };
};

/**
 * Generated by orval v6.25.0 🍺
 * Do not edit manually.
 * UpSmith Staging API
 * OpenAPI spec version: 1.0.0
 */

export type InvalidBulkUserUploadResponseResult =
  (typeof InvalidBulkUserUploadResponseResult)[keyof typeof InvalidBulkUserUploadResponseResult];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InvalidBulkUserUploadResponseResult = {
  invalid: "invalid",
} as const;

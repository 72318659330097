import { OrgMemberInfo, useAuthInfo } from "@propelauth/react";

export function useTenantUuid(): string {
  const authInfo = useAuthInfo();
  const orgs = authInfo.orgHelper?.getOrgs() ?? [];
  return getTenantFromOrgs(orgs);
}

export const getTenantFromOrgs = (orgs: Array<OrgMemberInfo>) => {
  if (orgs.length === 0) {
    throw new Error("User is not a member of an organization");
  }
  /* eslint-disable @typescript-eslint/no-explicit-any */
  const org = orgs[0] as any;
  return org.org_metadata.tenant_uuid;
};

import { useContext, useMemo } from "react";

import { OrgTranslator } from "./types";
import { useTranslate } from "./useTranslate";
import { OrgConfigContext } from "../OrgConfigProvider";

export const useOrgTranslator = (): OrgTranslator => {
  const orgConfigContext = useContext(OrgConfigContext);

  const translate = useTranslate(orgConfigContext);

  const point = useMemo(() => {
    return {
      title: translate("point", { casing: "title" }),
      lower: translate("point"),
    };
  }, [translate]);

  const points = useMemo(() => {
    return {
      title: translate("points", { casing: "title" }),
      lower: translate("points"),
    };
  }, [translate]);

  return {
    point,
    points,
    translate,
  };
};

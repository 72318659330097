import Link, { LinkProps } from "next/link";
import { NextRouter, useRouter } from "next/router";
import { ParsedUrlQuery } from "querystring";
import * as React from "react";

import { useAnalytics } from "@/lib/analytics/useAnalytics";
import { clsxm } from "@/lib/clsxm";

export type UnstyledLinkProps = {
  href: string;
  children: React.ReactNode;
  openNewTab?: boolean;
  className?: string;
  preserveQueryParams?: boolean;
  stopPropagation?: boolean;
  queryParams?: ParsedUrlQuery;
  nextLinkProps?: Omit<LinkProps, "href">;
} & React.ComponentPropsWithRef<"a">;

// NextRouter query includes dynamic route params eg /candidates/[candidateid] will have the candidateid as a query params
// We want to filter these out if we're preserving query params
function getSearchQueryParams(router: NextRouter) {
  const dynamicRouteKeys = router.pathname.match(/\[(.*?)\]/g);

  const queryParams = { ...router.query };

  Object.keys(queryParams).forEach((key) => {
    if (dynamicRouteKeys?.includes(`[${key}]`)) {
      delete queryParams[key];
    }
  });

  return queryParams;
}

export const UnstyledLink = React.forwardRef<
  HTMLAnchorElement,
  UnstyledLinkProps
>(
  (
    {
      children,
      href,
      openNewTab,
      className,
      nextLinkProps,
      preserveQueryParams,
      queryParams,
      stopPropagation,
      onClick,
      ...rest
    },
    ref
  ) => {
    const isNewTab =
      openNewTab !== undefined
        ? openNewTab
        : href && !href.startsWith("/") && !href.startsWith("#");

    const analytics = useAnalytics();

    const router = useRouter();

    if (!isNewTab) {
      return (
        <Link
          href={{
            pathname: href,
            query: preserveQueryParams
              ? { ...getSearchQueryParams(router), ...queryParams }
              : queryParams,
          }}
          onClick={(e) => {
            stopPropagation && e.stopPropagation();
            analytics.track("Link", "Click", { href });
            onClick && onClick(e);
          }}
          ref={ref}
          className={className}
          {...rest}
          {...nextLinkProps}
        >
          {children}
        </Link>
      );
    }

    return (
      <a
        ref={ref}
        target="_blank"
        rel="noopener noreferrer"
        href={href}
        onClick={() => {
          return analytics.track("External Link", "Click", { href });
        }}
        {...rest}
        className={clsxm("cursor-newtab", className)}
      >
        {children}
      </a>
    );
  }
);

/**
 * Generated by orval v6.25.0 🍺
 * Do not edit manually.
 * UpSmith Staging API
 * OpenAPI spec version: 1.0.0
 */

export type CompetitionTopNType =
  (typeof CompetitionTopNType)[keyof typeof CompetitionTopNType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompetitionTopNType = {
  top_n: "top_n",
} as const;

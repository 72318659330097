import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { captureException, withScope } from "@sentry/react";
import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { AxiosError } from "axios";
import { AppProps } from "next/app";
import { Work_Sans } from "next/font/google";
import NextNProgress from "nextjs-progressbar";
import * as React from "react";
import { ErrorBoundary } from "react-error-boundary";
import toast, { Toaster } from "react-hot-toast";
import { BrowserRouter } from "react-router-dom";
// Custom polyfill not yet available in `next-core`:
// https://github.com/vercel/next.js/issues/58242
// https://nextjs.org/docs/architecture/supported-browsers#custom-polyfills
import "core-js/features/array/to-sorted";

import "@/styles/globals.css";

import { AnalyticsProvider } from "@/lib/analytics/analyticsProvider";
import { RequiredAuthProvider } from "@/lib/auth";
import { OrgConfigProvider } from "@/lib/orgConfig";

import { propelAuthUrl } from "@/constant/env";
import { GenericErrorPage } from "@/pageComponents/Errors";
import LoginPage from "@/pages/login";

const workSans = Work_Sans({
  subsets: ["latin"],
  variable: "--font-worksans",
  display: "swap",
});

function App({ Component, pageProps }: AppProps) {
  const [queryClient] = React.useState(() => {
    const showAlert = (err: unknown) => {
      const error = err as AxiosError;
      if (error?.message) {
        toast.error(`Something went wrong: ${error.message}`);
      }
    };

    return new QueryClient({
      // TODO: improve handling of 400s
      mutationCache: new MutationCache({
        onError: (err, _variables, _context, mutation) => {
          withScope((scope) => {
            scope.setContext("mutation", {
              mutationId: mutation.mutationId,
              variables: mutation.state.variables,
            });
            if (mutation.options.mutationKey) {
              scope.setFingerprint(
                // Duplicate to prevent modification
                Array.from(mutation.options.mutationKey) as Array<string>
              );
            }
            captureException(err);
          });
        },
      }),
      queryCache: new QueryCache({
        onError: (err, query) => {
          withScope((scope) => {
            scope.setContext("query", { queryHash: query.queryHash });
            scope.setFingerprint([query.queryHash]);
            captureException(err);
          });
        },
      }),
      defaultOptions: {
        queries: {
          onError: showAlert,
        },
        mutations: {
          onError: showAlert,
        },
      },
    });
  });

  return (
    <QueryClientProvider client={queryClient}>
      <style jsx global>{`
        @font-face {
          font-family: ${workSans.style.fontFamily};
          font-style: normal;
          font-weight: normal;
          font-display: optional;
        }
      `}</style>
      <div className={`${workSans.variable} font-primary`}>
        <RequiredAuthProvider
          displayIfLoggedOut={<LoginPage />}
          authUrl={propelAuthUrl}
        >
          <OrgConfigProvider>
            <AnalyticsProvider>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <NextNProgress />
                <Toaster />
                <BrowserRouter>
                  <ErrorBoundary FallbackComponent={GenericErrorPage}>
                    <Component {...pageProps} />
                  </ErrorBoundary>
                </BrowserRouter>
                <ReactQueryDevtools initialIsOpen={false} />
              </LocalizationProvider>
            </AnalyticsProvider>
          </OrgConfigProvider>
        </RequiredAuthProvider>
      </div>
    </QueryClientProvider>
  );
}

export default App;

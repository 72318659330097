/**
 * Generated by orval v6.25.0 🍺
 * Do not edit manually.
 * UpSmith Staging API
 * OpenAPI spec version: 1.0.0
 */

export type SpotAwardMode = (typeof SpotAwardMode)[keyof typeof SpotAwardMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SpotAwardMode = {
  individual: "individual",
  team: "team",
} as const;

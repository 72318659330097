import { useAuthInfo } from "@propelauth/react";
import { useCallback, useMemo, useState } from "react";

import { useInfiniteSearch } from "@/components/hooks/useInfiniteSearch";
import { useTenantUuid } from "@/components/hooks/useTenantUuid";

import {
  getAllNotifications,
  GetAllNotificationsParams,
  useMarkNotificationAsRead,
} from "@/upsmith-api";

import { NotificationService } from "./types";

export const useNotificationService = (): NotificationService => {
  const [inMemoryMarkedRead, setInMemoryMarkedRead] = useState<Set<string>>(
    new Set()
  );

  const tenantUuid = useTenantUuid();

  const boundSearch = useCallback(
    (params: GetAllNotificationsParams) => {
      return getAllNotifications(tenantUuid, params);
    },
    [tenantUuid]
  );

  const infiniteSearch = useInfiniteSearch({
    searchFn: boundSearch,
    searchParams: {},
    responseKeyName: "notifications",
    cacheKeys: [],
    limit: 8,
  });

  const firstPage = infiniteSearch.data ? infiniteSearch.data.pages[0] : null;
  const metadata = firstPage?.response.metadata;
  const serverUnreadCount = metadata?.unread_count || 0;
  const notifications = infiniteSearch.notifications;

  const unreadCount = useMemo(() => {
    // We need to subtract the notifications that were marked as read since the last time we
    // loaded the unread count from the backend
    const markedReadSinceLoadingUnreadCount = notifications.filter(
      (notification) => {
        return (
          !notification.read_at && inMemoryMarkedRead.has(notification.uuid)
        );
      }
    );

    return serverUnreadCount - markedReadSinceLoadingUnreadCount.length;
  }, [serverUnreadCount, inMemoryMarkedRead, notifications]);

  const { mutate: markNotificationAsRead } = useMarkNotificationAsRead({
    mutation: {
      onSuccess: (data, { notificationUuid }) => {
        setInMemoryMarkedRead((prev) => {
          prev.add(notificationUuid);
          return prev;
        });
      },
    },
  });

  const authInfo = useAuthInfo();
  const isImpersonating = authInfo.isImpersonating;

  const markNotificationsAsRead = useCallback(
    (notificationUuids: Array<string>) => {
      if (isImpersonating) {
        return;
      }
      notificationUuids.forEach((uuid) => {
        markNotificationAsRead({ tenantUuid, notificationUuid: uuid });
      });
    },
    [isImpersonating, markNotificationAsRead, tenantUuid]
  );

  return {
    hasMoreNotifications: infiniteSearch.hasNextPage || false,
    inMemoryMarkedRead,
    fetchNextNotifications: infiniteSearch.fetchNextPage,
    markNotificationsAsRead,
    notifications: infiniteSearch.notifications,
    unreadCount,
  };
};
